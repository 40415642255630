// https://stackoverflow.com/a/58556477
const isDevReact = () => {
  return process.env.NODE_ENV === 'development';
};

class ApiClient {
  constructor(){
    if(isDevReact()){ // localhost
      this.rootUrl = "http://localhost:8000";
    }
    else if(window.location.hostname.includes("dev")){ // development version online
      this.rootUrl = "https://api.dev.test.powerreader.dk";
    }
    else {
      this.rootUrl = "https://api.test.powerreader.dk";
    }
    this.token = null;
  }

  generateError(message, code = 1){
    return {
      error: true,
      message: message,
      code: code
    };
  }

  async authorize(username, password){
    let response = await this.post({
      username: username,
      password: password
    }, 'user', 'token');
    if(response.api_token){
      this.setToken(response.api_token);
      return true;
    }
    else {
      return false;
    }
  }

  setToken(token){
    this.token = token;
  }

  generateUrl(endpoint){
    let url = this.rootUrl + endpoint;
    if(this.token !== null){
      url += "?token="+this.token;
    }
    return url;
  }

  async request(endpoint, method = 'get', body = {}){
    let url = this.generateUrl(endpoint);
    let extra = {};

    if(method === 'post'){
      extra = {
        ...extra,
        method: "POST",
        body: JSON.stringify(body)
      }
    }
    const request = new Request(url, extra);

    let response;
    try {
      response = response = await fetch(request);
    } catch (error) {
      return this.generateError("Could not load response");
    }

    let result = await response.json();

    return result ? result : this.generateError("Could not load response");

  }

  generateEndpoint(endpoints){
    return '/' + endpoints.map(x => encodeURIComponent(x)).join('/');
  }

  async get(...endpoints){
    let endpoint = this.generateEndpoint(endpoints);
    return await this.request(endpoint, 'get');
  }

  async post(body, ...endpoints){
    let endpoint = this.generateEndpoint(endpoints);
    return await this.request(endpoint, 'post', body);
  }

}


export default ApiClient;