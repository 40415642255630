import React from 'react';

import { Container, Row, Col, Alert, Collapse, Card, CardBody } from 'reactstrap';

import Categories from '../texts/readingCategories.json';
import ReadingCategoryTable from './ReadingCategoryTable';

import './css/ResultsDisplay.css';

class ResultsDisplay extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      showCategoryDescription: false,
    };
  }

  renderCategory(category, isChosenCategory){
    let title = category.title;
    let color = category.color;

    let style = {
      backgroundColor: color
    };

    let rowClassName = "category-row";
    if(isChosenCategory){
      rowClassName = rowClassName + " is-chosen"
    }

    return (
      <div className={rowClassName} style={style}>
        <span className="title">{title}</span> <br />
      </div>
    );
  }

  renderPotential(potentialIncrease){
    return (
      <React.Fragment>
        <a
          href={this.props.potentialIncreaseLink}
          onClick={this.props.onClickPotentialIncreaseLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Du vil dermed kunne komme til at læse op til <span className="meassure">{potentialIncrease} gange hurtigere</span>!
        </a>
      </React.Fragment>
    );
  }

  renderExplaination(description){
    return (
      <Collapse isOpen={this.state.showCategoryDescription}>
        <Card>
          <CardBody>
          {description}
          </CardBody>
        </Card>
      </Collapse>
    );
  }

  render(){
    let categoryNumber = this.props.readingCategory;
    let wpm = this.props.wpm;
    let percentCorrect = this.props.percentCorrect;

    let readingPotential = 1000; // the maximal number of WPM achievable generally
    let potentialIncrease = this.props.potentialIncrease;

    let category = Categories.find(cat => cat.number === categoryNumber);
    if(category === undefined){
      return (
        <Alert color="danger">
          <b>Fejl:</b> Der skete en fejl da vi skulle vise dit resultat.
        </Alert>
      );
    }

    let sortedCategories = Categories.sort((a, b) => b.number - a.number);

    // const changeShowCategoryDescription = () => this.setState({
    //   showCategoryDescription: !this.state.showCategoryDescription
    // });

    return (
      <div className="ResultsDisplay">
        <Container style={{padding: '0'}}>
          <Row>
            <Col md={5} style={{marginBottom: "15px"}}>
              <ReadingCategoryTable
                readingCategory={categoryNumber}
                categories={sortedCategories}
              />

            </Col>
            <Col>
              <p>
                Din læsehastighed er <span className="meassure">{wpm} ord per minut</span> og du svarede rigtigt på <span className="meassure">{percentCorrect}%</span> af spørgsmålene, hvilket gør dig til en <span className="meassure">{category.title}</span>.
              </p>
              <p>
              Vores erfaring viser, at de fleste voksne danskere læser med en læsehastighed mellem 130 og 250 ord per minut. Vores læsepotentiale er helt op til {readingPotential} ord per minut. { readingPotential > wpm ? this.renderPotential(potentialIncrease) : "" }
              </p>
              {/* <Button
                color="link"
                onClick={() => changeShowCategoryDescription()}
              >Læs mere</Button> */}
            </Col>
          </Row>
        </Container>


        {this.renderExplaination(category.description)}


      </div>
    );
  }
}



export default ResultsDisplay;