import React from 'react';
import { Button, Row, Col } from "reactstrap";

import YouTube from './YouTube';

import './css/Welcome.css';

import { bossTitle, employeeTitle, bossMessage, bossVideo, employeeMessage, employeeVideo, introText } from '../texts/welcome.json';


class Welcome extends React.Component {



  renderWelcomeMessage(){
    let msg = this.props.shouldTakeBossTest ? bossMessage : employeeMessage;
    return (
      <div className="welcome-message">
        { msg }
      </div>
    );
  }

  renderEmployeeMessage(){
    return employeeMessage;
  }


  renderIntroText(){
    return (
      <div className="introtext">
        {introText}
      </div>
    );
  }

  renderHeader(){
    const renderCompany = () => {
      if(this.props.shouldTakeBossTest){
        return "";
      }
      let companyName = this.props.companyName;
      return (
        <center><h3>{companyName}</h3></center>
      );
    }

    let title = this.props.shouldTakeBossTest ? bossTitle : employeeTitle;

    return (
      <div className="header">
        <Row>
          <Col>
            <center><h1>{title}</h1></center>
            {renderCompany()}
          </Col>
        </Row>
      </div>
    );
  }

  renderButton(){
    return (
      <div className="start-button-wrapper">
        <center>
          <Button
            className="start-button"
            color="primary"
            size="lg"
            onClick={() => this.props.onStartTest()}
          >
            Start tiden og tag testen
          </Button>
        </center>
      </div>
    );
  }

  renderIntroVideo(){
    let videoId = this.props.shouldTakeBossTest ? bossVideo : employeeVideo;

    return (
      <YouTube
        videoId={videoId}
      />
    );
  }



  render() {
    return (
      <div className="Welcome">
        { this.renderHeader() }

        { this.renderWelcomeMessage() }

        { this.renderIntroVideo() }

        { this.renderIntroText() }

        { this.renderButton() }
      </div>
    );
  }
}



export default Welcome;