import React from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from "reactstrap";
import './css/QuestionsPage.css';


class QuestionsPage extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      selelectedAnswers: {},
      message: ''
    };
  }

  renderAnswer(answer, answerId, questionId){
    const onSelectAnswer = () => {
      this.setState({message: ''}); // reset error message

      let selelectedAnswers = Object.assign({}, this.state.selelectedAnswers); // Make a shallow copy
      selelectedAnswers[questionId] = answer;
      this.setState({
        selelectedAnswers: selelectedAnswers
      });
    }

    let inputName = "radio" + questionId;
    let inputId = "radio" + questionId + "_" + answerId;

    return (
      <FormGroup check key={answerId}>
        <Input type="radio" name={inputName} id={inputId} onChange={onSelectAnswer} />{' '}
        <Label check for={inputId}>
          {answer}
        </Label>
      </FormGroup>
    );
  }

  renderQuestion(question){
    let answers = question.answers.map((a, id) => this.renderAnswer(a, id, question.id));
    return (
      <FormGroup tag="fieldset" key={question.id}>
        <legend>{question.text}</legend>
        {answers}
      </FormGroup>
    );
  }

  renderQuestions(){
    let questions = this.props.questions.map(q => this.renderQuestion(q));
    return (
      <Form>
        {questions}
      </Form>
    );
  }

  renderSubmit(){
    const onSubmitAnswers = () => {
      let questions = this.props.questions;
      const selelectedAnswers = this.state.selelectedAnswers;

      // there exists an answer for each question
      let allQuestionsAnswered = questions.every(q => {
        let answer = selelectedAnswers[q.id];
        return answer && q.answers.includes(answer);
      });

      if(allQuestionsAnswered){
        this.props.onSubmitAnswers(selelectedAnswers);
      }
      else {
        this.setState({
          message: "Svar venligst på alle spørgsmål"
        });
      }
    }

    return (
      <div style={{marginTop: '20px'}}>
        {this.renderMessage()}
        <center>
          <Button
            className="next-button"
            size="lg"
            color="primary"
            onClick={onSubmitAnswers}
          >
            Indsend svar og se resultater
          </Button>
        </center>
      </div>
    );
  }

  renderMessage(){
    let message = this.state.message;

    if(message !== ''){
      return (
      <Alert color="danger" fade={false}>
        {message}
      </Alert>
      );
    }
    return '';
  }

  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to top
  }

  render(){
    return (
      <div className="QuestionsPage">
        <h1>Svar på spørgsmålene</h1>
        {this.renderQuestions()}
        {this.renderSubmit()}
      </div>
    );
  }
}

export default QuestionsPage;