import React from 'react';

import { Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';

class CreateCompanyForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      message: '',
      name: '',
      comments: ''
    }

  }

  setLoading(){
    this.setState({ loading: true });
  }

  setNotLoading(){
    this.setState({ loading: false });
  }

  setMessage(msg){
    this.setState({ message: msg });
  }


  async onCreate(e){
    e.preventDefault();
    this.setLoading();
    let name = this.state.name;
    let comments = this.state.comments;

    let success = await this.props.onCreate(name, comments);

    if(success){
      this.setMessage('Oprettede firma');
      this.state.name = '';
      this.state.comments = '';
    }
    else {
      this.setMessage('Kunne ikke oprette firma');
    }

    this.setNotLoading();
  }

  setName(e){
    this.setState({ name: e.target.value });
  }

  setComments(e){
    this.setState({ comments: e.target.value });
  }

  renderLoading(){
    return (
      <div>
        Vent venligst <br />
        <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
      </div>
    );
  }

  render() {
    if(this.state.loading){
      return this.renderLoading();
    }

    return (
      <div>
        <b>{this.state.message}</b>
        <Form target="#" onSubmit={e => this.onLogin(e)}>
          <FormGroup>
            <Label for="name">Firmanavn</Label>
            <Input type="text" name="name" value={this.state.name} onChange={(e) => this.setName(e)} />
          </FormGroup>
          <FormGroup>
            <Label for="comments">Kommentarer:</Label>
            <Input
              type="textarea"
              name="comments"
              onChange={e => this.setComments(e)}
              value={this.state.comments}
            />
          </FormGroup>
          <Button onClick={e => this.onCreate(e)}>Opret firma</Button>
        </Form>
      </div>
    );
  }
}


export default CreateCompanyForm;