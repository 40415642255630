import React from 'react';

// Components:
import ResultsTable from './ResultsTable';
import EmployeeSignupForm from './EmployeeSignupForm';
import AdminPagination from './AdminPagination';

class ShowCompany extends React.Component {

  renderSignup(){
    return (
      <div>
        <h2>Tilmeld til test</h2>
        <EmployeeSignupForm
          onSignup={this.props.onSignup}
        />
      </div>
    );
  }

  renderResultsPagination(){
    return (
      <AdminPagination
        currentPage={this.props.resultsCurrentPage}
        lastPage={this.props.resultsLastPage}
        onSwitchPage={number => this.props.resultsOnSwitchPage(number)}
      />
    );
  }

  renderResults(){
    let people = this.props.results.people;

    if(people.length === 0){
      return "Ingen pesoner fundet";
    }

    return (
      <div>
        <h2>Resultater</h2>
        <ResultsTable
          people={people}
        />
        {this.renderResultsPagination()}
      </div>
    );
  }

  renderInfo(){

    // Deactivated fields included:
    //     Kan mødes: {company.meeting_time}
    //     Kommentarer: {company.comments}
    //     Addresse: {contact.address}

    let company = this.props.company;
    let contact = this.props.contact;

    let contactPart = (<span>Ikke tilgængelig</span>)

    if(contact){
      contactPart = (
        <span>
          Email: {contact.email} <br />
          Navn: {contact.name} <br />
          Telefon: {contact.phone} <br />
        </span>
      );
    }


    return (
      <div>
        <h2>Om</h2>
        Firmanavn: {company.name} <br/><br/>
        Kontaktperson: <br />
        {contactPart}
      </div>
    );

  }

  render() {
    return (
      <div className="ShowCompany">
        {this.renderInfo()} <br />
        {this.renderResults()} <br />
        {this.renderSignup()}
      </div>
    );
  }
}


export default ShowCompany;