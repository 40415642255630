import React from 'react';
import './css/Login.css';

import { Button, Form, FormGroup, Label, Input } from 'reactstrap';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      user: ''
    }

  }

  onLogin(e){
    e.preventDefault();
    let password = this.state.password;
    let user = this.state.user;
    this.props.onLogin(user, password);
  }

  setPassword(e){
    this.setState({
      password: e.target.value
    });
  }

  setUser(e){
    this.setState({
      user: e.target.value
    });
  }

  render() {
    return (
      <Form target="#" onSubmit={e => this.onLogin(e)}>
        <FormGroup>
          <Label for="user">Brugernavn</Label>
          <Input type="text" name="user" onChange={(e) => this.setUser(e)} />
        </FormGroup>
        <FormGroup>
          <Label for="password">Password</Label>
          <Input type="password" name="password" onChange={(e) => this.setPassword(e)} />
        </FormGroup>
        <Button onClick={e => this.onLogin(e)}>Log ind</Button>
      </Form>
    );
  }
}


export default Login;