import React from 'react';

import LogoImage from '../images/logo.png';
import './css/LogoHeader.css';

class LogoHeader extends React.Component {

  // Will render a loading message.
  render(){
    return (
      <div className="LogoHeader">
        <center>
          <a href="https://powerreader.dk/" target="_blank" rel="noopener noreferrer">
            <img src={LogoImage} alt="logo" />
          </a>
        </center>
      </div>
    );
  }
}


export default LogoHeader;