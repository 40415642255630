import React from 'react';
import ApiClient from '../ApiClient/ApiClient';
import CookieHelper from '../Utils/CookieHelper';
import './css/AdminRoot.css';

// Components:
import Login from './Login';
import ShowCompanies from './ShowCompanies';
import ShowCompany from './ShowCompany';
import ShowStatistics from './ShowStatistics';
import AdminMenu from './AdminMenu';
import Loading from './Loading';

class AdminRoot extends React.Component {

  constructor(props){
    super(props);

    this.api = new ApiClient();

    this.state = {
      loading: false,
      error: null,
      page: 'Login',
      pageState: null
    };
  }

  componentDidMount(){
    this.checkCookieToken();
  }




  /************
  Login
  ************/

  logout(){
    CookieHelper.deleteCookie('admin_token');
    this.setPage('Login');
    this.api = new ApiClient();
  }

  checkCookieToken(){
    const validateToken = async token => {
      this.api.setToken(token);
      let response = await this.api.get('admin', 'check_token');
      if(response.error !== false){
        this.logout();
        return false;
      }
      else {
        CookieHelper.setCookie('admin_token', token, 3);
        return true;
      }
    }

    let token = CookieHelper.getCookie('admin_token');
    if(token !== ""){
      let isValid = validateToken(token);
      if(isValid){
        this.loadShowCompanies();
      }
      else {
        this.loadLogin();
      }
    }
  }

  async performAuth(username, password){
    this.setIsLoading();

    let valid = await this.api.authorize(username, password);

    if(valid){
      CookieHelper.setCookie('admin_token', this.api.token, 3);
      this.loadShowCompanies();
    }
    else {
      this.loadLogin();
      this.setPageState({
        invalidCredentials: true
      });
    }
  }

  /************
  Utils
  ************/
  setIsLoading(){
    this.setState({loading: true});
  }

  setNotLoading(){
    this.setState({loading: false});
  }

  setPageState(state){
    this.setState({ pageState: state });
  }

  setNoPageState(){
    this.setState({ pageState: null });
  }

  setPage(page){
    this.setState({ page: page });
  }

  objectHasError(...objs){
    return objs.some(obj => {
      return obj && obj.error === true;
    });
  }

  setError(errorObject){
    this.setState({ error: errorObject });
  }

  setNoError(){
    this.setState({ error: null });
  }

  beforeLoad(page){
    this.setIsLoading();
    this.setNoPageState();
    this.setPage(page);
  }

  afterLoad(page, pageState = null){
    this.setPageState(pageState);
    if(pageState && this.objectHasError(pageState)){
      this.setError({
        error: true,
        code: 0,
        message: pageState.message ? pageState.message : "Der skete en fejl"
      });
    }
    else {
      this.setNoError();
    }

    this.setPage(page);
    this.setNotLoading();
  }

  hasError(){
    return this.error !== null;
  }

  getErrorMessage(){
    if(this.state.error && this.state.error.message){
      return this.state.error.message;
    }
    else {
      return null;
    }
  }

  /************
  Routing system
  ************/
  /* Page layout */

  render() {
    return (
      <div className='AdminRoot'>
        {this.state.page === 'Login' ? '' : this.renderMenu()}
        {this.renderPage()}
      </div>
    );
  }

  renderLoading(){
    return ( <Loading />);
  }

  renderMenu(){
    return (
      <AdminMenu
        selectShowCompanies={() => this.loadShowCompanies()}
        selectShowStatistics={() => this.loadShowStatistics()}
        onLogout={() => this.logout()}
      />
    );
  }

  renderError(){
    return (
      <div>
        Fejl: {this.state.error.message}
      </div>
    );
  }

  // Will render the content from State
  renderPage(){
    let page = this.state.page;
    if(this.state.error){
      return this.renderError();
    }
    if(this.state.loading){
      return this.renderLoading();
    }
    if(page === 'Login'){
      return this.renderLogin();
    }
    if(page === 'ShowCompanies'){
      return this.renderShowCompanies();
    }
    if(page === 'ShowCompany'){
      return this.renderShowCompany();
    }
    if(page === 'ShowStatistics'){
      return this.renderShowStatistics();
    }
    return 'Kunne ikke finde siden';
  }

  /************
  Pages
  ************/

  /* Login */
  loadLogin(){
    this.beforeLoad('Login');
    this.afterLoad('Login', null)
  }

  renderLogin(){
    let hasError = this.state.pageState && this.state.pageState.invalidCredentials
    let errorMessage = hasError ? 'Forkert kodeord eller brugernavn' : '';
    return (
      <React.Fragment>
        {errorMessage}
        <Login onLogin={(username, password) =>this.performAuth(username, password)} />
      </React.Fragment>
    );
  }

  /* ShowStatistics */
  async loadShowStatistics(){
    this.beforeLoad('ShowStatistics');

    let response = await this.api.get('admin', 'statistics');

    let pageState = {
      ...response
    }

    this.afterLoad('ShowStatistics', pageState);
  }

  renderShowStatistics(){
    let pageState = this.state.pageState;

    console.log("pageState", pageState);

    return (
      <ShowStatistics
        byBirthYear={pageState.by_birth_year}
        byGender={pageState.by_gender}
        byWpm={pageState.by_wpm}
        byReadingCategory={pageState.by_reading_category}
        all={pageState.all}
      />
    );
  }

  /* ShowCompanies */
  async loadShowCompanies(page = 0){
    this.beforeLoad('ShowCompanies');

    let response = await this.api.get('admin', 'companies', page);

    let pageState = {
      currentPage: page,
      ...response
    }

    this.afterLoad('ShowCompanies', pageState);
  }

  renderShowCompanies(){
    const createCompany = async (name, comments) => {
      let response = await this.api.post({
        name: name,
        comments: comments
      }, 'admin', 'company');

      return !response.error;
    }

    let pageState = this.state.pageState;
    let currentPage = pageState.currentPage;
    let lastPage = pageState.last_page;

    return (
      <ShowCompanies
        currentPage={currentPage}
        lastPage={lastPage}
        onSwitchPage={pageNo => this.loadShowCompanies(pageNo)}
        onSelectCompany={id => this.loadShowCompany(id)}
        onCreateCompany={(name, comments) => createCompany(name, comments)}
        companies={pageState.companies}
      />
    );
  }

  /* ShowCompany */
  async loadShowCompany(id, resultsPage = 0){
    this.beforeLoad('ShowCompany');

    let company = await this.api.get('admin', 'company', id);
    let results = await this.api.get('admin', 'company', id, 'results', resultsPage); // To-do: concurrent requests

    let contact = null

    if(!this.objectHasError(company) && company.contact_id){
      let contact_id = parseInt(company.contact_id)
      contact = await this.api.get('admin', 'person', contact_id);
    }


    let pageState = {
      error: this.objectHasError(company, results),
      company: company,
      results: {
        currentPage: resultsPage,
        ...results
      },
      contact: contact
    }

    this.afterLoad('ShowCompany', pageState);
  }

  renderShowCompany(){
    let pageState = this.state.pageState;

    // Sign 'email' up for test 'testId'
    const onSignup = async (testId, companyId, email, deadline) => {
      let response = await this.api.post({
        email: email,
        deadline: deadline
      }, 'admin', 'testcase', testId, companyId, 'new');
      if(response.error){ // Could not sign up
        return false;
      }
      return true;
    }

    let companyId = pageState.company.id;

    return (
      <ShowCompany
        company={pageState.company}
        results={pageState.results}
        contact={pageState.contact}
        resultsCurrentPage={pageState.results.currentPage}
        resultsLastPage={pageState.results.last_page}
        resultsOnSwitchPage={pageNo => this.loadShowCompany(companyId, pageNo)}
        onSignup={(testId, email, deadline) => onSignup(testId, companyId, email, deadline)}
      />
    );

  }

  /* */


}




export default AdminRoot;