import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import './css/App.css';
import TestRoot from './TestRoot';
import AdminRoot from './AdminRoot';
// import SignupRoot from './SignupRoot';

import BackgroundImage from '../images/background.svg';

const isEmbed = () => window.location.pathname.startsWith('/embed');

class App extends React.Component {

  renderAdmin(){
    return (
      <AdminRoot />
    );
  }

  renderTest(){
    return (
      <TestRoot
        isEmbed={isEmbed()}
      />
    );
  }

  // renderSignup(){
  //   return (
  //     <SignupRoot />
  //   );
  // }

  render404(){
    return (
      <div>
        Siden du forespurgte kunne ikke findes
      </div>
    );
  }

  renderPage() {
    let pathname = window.location.pathname;

    // Very simple routing system!
    const route = page => pathname.startsWith('/' + page);

    if(route('admin')) {
      return this.renderAdmin();
    }
    else if(route('invite') || route('')) {
      return this.renderTest();
    }
    // else if(route('')) {
    //   return this.renderSignup();
    // }
    else {
      return this.render404(); // Dead code at the moment
    }
  }

  render(){
    const backgroundStyleDesktop = {
      backgroundImage:
        "linear-gradient(0deg, rgba(18, 56, 90, 0.85), rgba(18, 56, 90, 0.9))"
        + ",url(" + BackgroundImage + ")"
    };
    const backgroundStyleMobile = { backgroundColor: "#f8f9fb" };

    const isMobile = window.innerWidth <= 570;
    const backgroundStyle = isMobile ? backgroundStyleMobile : backgroundStyleDesktop;

    if(isEmbed()){
      // Fix for Reactstrap and full height iFrames
      document.body.style.setProperty('height', 'unset');
      document.getElementById('root').style.setProperty('height', 'unset');
      return (
        <div className="App is-embed" style={{backgroundColor: "#ffffff"}}>
          <div className="site-inner">
            {this.renderPage()}
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="App is-not-embed" style={backgroundStyle}>
          <Container style={{height: "100%"}}>
            <Row style={{height: "100%"}}>
              <Col sm="12" md={{ size: 8, offset: 2 }} className="site-background">
                <div className="site-inner">
                  {this.renderPage()}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  }
}


export default App;