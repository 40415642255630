import React from 'react';
import './css/TestRoot.css';
import { Spinner } from "reactstrap"

class Loading extends React.Component {

  // Will render a loading message.
  render(){
    return (
      <div>
        <center>
          <h3 className="col-12">
            Vent venligst
          </h3>
          <Spinner color="info"/>
        </center>
      </div>
    );
  }
}


export default Loading;