import React from 'react';

import './css/YouTube.css';

class YouTube extends React.Component {

  render(){
    let youtubeId = this.props.videoId;
    let youtubeEmbedUrl = "https://www.youtube-nocookie.com/embed/" + youtubeId;
    youtubeEmbedUrl += "?modestbranding=1&autohide=1&controls=1&rel=0"; // Settings
    return (
      <div className="YouTube">
        <center>
          <iframe
            title="Video"
            src={youtubeEmbedUrl}
            frameBorder="0"
            //allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </center>
      </div>
    );
  }
}



export default YouTube;