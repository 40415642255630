import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import { privacyLink, cookiePopupText, cookiePopupLinkText } from '../texts/privacy.json';

import CookiesEnabledIcon from '../images/cookies_enabled.png';
import CookiesDisabledIcon from '../images/cookies_disabled.png';

import './css/CookiePopup.css';

class CookiePopup extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    }
  }

  onClickAcceptButton(){
    this.props.onAcceptCookies();
    this.setState({modalIsOpen: false});
  }

  onClickDenyButton(){
    this.props.onDenyCookies();
    this.setState({modalIsOpen: false});
  }

  renderDialogue(){
    return (
      <React.Fragment>
        { cookiePopupText }
        <a
          href={privacyLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {cookiePopupLinkText}
        </a>{' '}
        <div className="CookiePopup-buttons">
          <Button
            onClick={() => this.onClickDenyButton()}
            color="secondary"
          >Afvis</Button>{' '}
          <Button
            onClick={() => this.onClickAcceptButton()}
            color="success"
          >Acceptér</Button>
        </div>
      </React.Fragment>
    );
  }

  renderSmall(){
    const toggleModal = () => {this.setState({modalIsOpen: !this.state.modalIsOpen})};

    let hasAcceptedCookies = this.props.hasAcceptedCookies;

    let currentConsentTextAccepted = "Du har givet samtykke til trackere, men du kan trække dette tilbage her.";
    let currentConsentTextDenied = "Du har ikke givet samtykke til trackere, men det kan du gøre her.";
    let currentConsentText = hasAcceptedCookies ? currentConsentTextAccepted : currentConsentTextDenied;

    let icon = hasAcceptedCookies ? CookiesEnabledIcon : CookiesDisabledIcon;

    return (
      <div className="CookiePopup CookiePopup-small">
        <img onClick={toggleModal} src={icon} alt="cookie icon" />
        <Modal isOpen={this.state.modalIsOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Indstillinger for tracking</ModalHeader>
          <ModalBody>
            {currentConsentText + ' '}
            {this.renderDialogue()}
          </ModalBody>
        </Modal>
      </div>
    )
  }

  renderFull(){
    return (
      <div className="CookiePopup CookiePopup-full">
        {this.renderDialogue()}
      </div>
    )
  }

  render(){
    let shouldRenderSmallBox = this.props.renderSmall;
    return shouldRenderSmallBox ? this.renderSmall() : this.renderFull();
  }
}

export default CookiePopup;