import React from 'react';

import './css/SocialShareButtons.css';

import FacebookLogo from '../images/facebook_logo.png';
import LinkedInkLogo from '../images/linkedin_logo.png';
import ShareIcon from '../images/share_icon.png';

class SocialShareButtons extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasClickedMobileShare: false
    }

    // window.navigator.canShare() is not working due to lack of broswer support
    this.canShare = (typeof window.navigator.share === 'function');

  }

  renderPopupLink(name, link, logo, width = 600, height = 800){
    let onClick = () => {
      this.props.trackShare && this.props.trackShare(name);
      window.open(link,'popup','width='+width+',height='+height)
    }
    return (
      <a
        href={link}
        target="popup"
        rel="noopener noreferrer"
        onClick={onClick}
      >
        <img
          alt={name + " logo"}
          src={logo}
        />
      </a>
    );
  }

  shouldRenderWebsiteShare(){
    // Should render LinkedIn and Facebook buttons
    return this.state.hasClickedMobileShare || !this.shouldRenderMobileShare();
  }

  shouldRenderMobileShare(){
    const isMobile = window.innerWidth <= 570;
    // Should render share using
    return this.canShare && isMobile;
  }

  renderMobileShare(){
    let onClick = () => {
      this.props.trackShare && this.props.trackShare('MobileShareDialogue');

      window.navigator.share({
        url: this.props.url
      })
      .then(() => {
        console.log('Share was successful.');
        this.setState({hasClickedMobileShare: true});
      })
      .catch((error) => {
        console.log('Sharing failed', error);
        this.setState({hasClickedMobileShare: true});
      });

    };
    return (
      <img
        onClick={onClick}
        alt="Share"
        src={ShareIcon}
      />
    );
  }

  renderFacebookShare(){
    let link = "https://www.facebook.com/sharer/sharer.php?u=" + this.props.url;
    return this.renderPopupLink("Facebook", link, FacebookLogo)
  }

  renderLinkedInShare(){
    let link = "https://www.linkedin.com/shareArticle?mini=true&url=" + this.props.url;
    return this.renderPopupLink("LinkedIn", link, LinkedInkLogo)
  }

  render(){
    return (
      <div className="SocialShareButtons" style={this.props.style}>
        {this.shouldRenderMobileShare() ? this.renderMobileShare() : ""}
        {this.shouldRenderWebsiteShare() ? this.renderFacebookShare() : ""}
        {this.shouldRenderWebsiteShare() ? this.renderLinkedInShare() : ""}
      </div>
    );
  }
}



export default SocialShareButtons;