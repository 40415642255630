import CookieHelper from './CookieHelper';

import FacebookPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';
import GoogleAnalytics from 'react-ga';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

class TrackingHelper {
  constructor(fbId = null, linkedInId = null, googleAnalyticsId = null){
    this.eventQueue = []; // When a user has not accepted cookie yet, all tracking actions will be queued
    this.fbId = fbId; // Facebook Pixel id
    this.linkedInId = linkedInId; // LinkedIn Partner ID
    this.googleAnalyticsId = googleAnalyticsId; // Google Analytics Id (eg. UA-000000-01)

    this.hasAcceptedTrackingVariable = false; // backup for the case where cookies might not work
    this.hasAcceptedTrackingVariable = this.hasAcceptedTracking();

    this.trackEvent({type: 'initializeScripts'}); // Scripts will be initialized when consent is given
  }

  hasMadeTrackingDecision(){
    let cookieValue = CookieHelper.getCookie('hasAcceptedCookies');
    return cookieValue !== "";
  }

  hasAcceptedTracking(){
    let cookieValue = CookieHelper.getCookie('hasAcceptedCookies');
    if(cookieValue === "true"){
      return true;
    }
    else if(this.hasAcceptedTrackingVariable === true){
      console.warn("PowerReader tracking cookie does not work")
      return true;
    }
    return false;
  }

  async acceptTracking(){
    this.hasAcceptedTrackingVariable = true;
    CookieHelper.setCookie('hasAcceptedCookies', true, 90);
    await this._performEventQueue();
  }

  denyTracking(){
    this.hasAcceptedTrackingVariable = false;
    CookieHelper.setCookie('hasAcceptedCookies', false, 90);
    FacebookPixel.revokeConsent();
    this.trackEvent({type: 'initializeScripts'});  // Scripts will be initialized if consent is given again
  }

  async trackEvent(event){
    event.timestamp = new Date();
    if(!this.hasAcceptedTracking()){ // Just save the event for later
      this.eventQueue.push(event);
    }
    else {
      this._performTrackEvent(event);
    }
  }

  async _performTrackEvent(event){
    if(this.hasAcceptedTracking()){ // make sure the user has consented
      switch (event.type) { // Perform the event called from trackEvent()
        case 'pageView':
          await this._trackPageView(event);
          break;
        case 'buttonClick':
          await this._trackButtonClick(event);
          break;
        case 'linkClick':
          await this._trackLinkClick(event);
          break;
        case 'initializeScripts':
          await this._initAll();
          break;
        default:
          console.warn("Unknown tracking event: ", event);
          break;
      }
    }
  }

  // Eg: {type: 'pageView', pageName: 'Welcome', pagePath: '/some/path?a=x#Welcome'}
  async _trackPageView(event){
    let pageName = event.pageName;
    let pagePath = event.pagePath;
    // Facebook:
    FacebookPixel.pageView();
    FacebookPixel.trackCustom('VisitedPage', {pageName: pageName, pagePath: pagePath});

    // LinkedIn:
    // LinkedInTag.track(conversionId); // TODO: add tracking

    // Google Analytics:
    GoogleAnalytics.pageview(pagePath);
    GoogleAnalytics.event({
      category: 'Page',
      action: 'Visited a page in the flow',
      label: pageName
    });
  }

  // Eg: {type: 'buttonClick', label: 'book demomeeting'}
  async _trackButtonClick(event){
    let label = event.label;

    console.log("Clicked button with label", label);

    GoogleAnalytics.event({
      category: 'Button',
      action: 'Clicked a button',
      label: label
    });
  }

  // Eg: {type: 'linkClick', label: 'explain results potential'}
  async _trackLinkClick(event){
    let label = event.label;

    console.log("Clicked linked with label", label);

    GoogleAnalytics.event({
      category: 'Link',
      action: 'Clicked a link',
      label: label
    });
  }

  async _performEventQueue(){
    while(this.eventQueue.length > 0){
      let event = this.eventQueue.shift(); // Return and remove first element of array
      await this._performTrackEvent(event);
    }
  }

  async _initAll(){
    await this._initFbPixel();
    await this._initLinkedIn();
    await this._initGoogleAnalytics();
    // TODO: currently, the awaits doesn't work
    // So we just sleep 2 seconds to avoid race conditions:
    await sleep(2000);
  }

  async _initFbPixel(){
    if(this.fbId === null) return;
    // See https://www.npmjs.com/package/react-facebook-pixel
    const advancedMatching = {  };
    const options = {
      autoConfig: true,
      debug: false,
    };
    FacebookPixel.init(this.fbId, advancedMatching, options);
    FacebookPixel.grantConsent();
  }

  async _initLinkedIn(){
    if(this.linkedInId === null) return;
    // See https://www.npmjs.com/package/react-linkedin-insight
    let disabled = false; // at this point, consent is given
    await LinkedInTag.init(this.linkedInId, 'dc', disabled);
  }

  async _initGoogleAnalytics(){
    if(this.googleAnalyticsId === null) return;
    let options = {
      debug: false,
    }
    GoogleAnalytics.initialize(this.googleAnalyticsId, options);
  }

}

export default TrackingHelper;
