import React from 'react';
import './css/TestRoot.css';
import ApiClient from '../ApiClient/ApiClient';
import TrackingHelper from '../Utils/TrackingHelper';
import { fbPixelId, linkedInPartnerId, googleAnalyticsId } from '../texts/privacy.json';
import { Alert } from "reactstrap"
//Components:
import Welcome from './Welcome';
import ReadingPage from './ReadingPage';
import QuestionsPage from './QuestionsPage';
import ResultsPage from './ResultsPage';
import Loading from './Loading';
import LogoHeader from './LogoHeader';
import CookiePopup from './CookiePopup';

const getInviteKeyFromUrl = () => {
  let res = window.location.pathname.match(/\/invite\/(.+)/);
  return res === null ? null : res[1];
}

// https://stackoverflow.com/a/39914235
const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

class TestRoot extends React.Component {
  constructor(props){
    super(props);

    // State should always be initialized in the constructor
    this.state = {
      isLoading: true,
      apiResponse: null,
      error: null,
      page: 'Loading',
      readingTime: 0,
      inviteKey: '',
      shouldTakeBossTest: true
    };

    this.api = new ApiClient();
    this.trackingHelper = new TrackingHelper(fbPixelId, linkedInPartnerId, googleAnalyticsId);
  }


  // This function is used for loading data from the API
  // The results are set to the State, which will make the Component re-render
  async loadContent(){
    let inviteKey = getInviteKeyFromUrl();

    if(inviteKey === null){
      let testId = 1;
      let res = await this.api.post({}, 'testcase', testId, 'new');
      inviteKey = res.invite_key;
    }

    this.setState({
      inviteKey: inviteKey
    })

    let response = await this.api.get('testcase', inviteKey);

    // Set api response
    if(response.error){
      this.setState({
        apiResponse: null,
        isLoading: false,
        error: response
      });
    }
    else {
      this.setState({
        apiResponse: response,
        shouldTakeBossTest: response.shouldTakeBossTest,
        isLoading: false,
        error: null,
        page: 'Welcome'
      });
      this.goToPage('Welcome');
    }
  }

  componentDidMount(){
    this.loadContent();
  }

  goToPage(page){
    // tracking
    if(page !== 'Loading'){
      let pagePath = window.location.pathname + window.location.search + '#' + page;
      this.trackingHelper.trackEvent({type: "pageView", pageName: page, pagePath: pagePath});
    }

    // react state
    this.setState({
      page: page
    });

    // for embedding
    if(this.props.isEmbed){
      setTimeout(() => { // wait in order to get the correct height
        window.top.postMessage({
          eventType: "goToPage",
          page: page,
          windowHeight: document.body.scrollHeight
        }, '*');
      }, 100);
    }
  }

  setLoading(){
    this.setState({ isLoading: true });
    this.goToPage('Loading');
  }

  // Will render the content from State
  renderPage(){
    let page = this.state.page;
    if(this.state.error){
      return this.renderErrorMessage(this.state.error.message);
    }
    if(page === 'Welcome'){
      return this.renderWelcome();
    }
    if(page === 'Reading'){
      return this.renderReading();
    }
    if(page === 'Questions'){
      return this.renderQuestions();
    }
    if(page === 'Goodbye'){
      return this.renderGoodbye();
    }
    return this.renderLoadingMessage();
  }

  /*
  Pages
  */

  // Will render a loading message.
  renderLoadingMessage(){
    return ( <Loading /> );
  }

  // Will render an error message for a given message input
  renderErrorMessage(message){
    return (
      <div>
        <Alert color="danger">
          <b>Fejl:</b> {message}
        </Alert>
        Prøv eventuelt at genindlæse siden. Hvis det ikke virker, kontakt susan@timeglasset.dk
      </div>
    );
  }

  renderWelcome(){
    let companyName = '';
    if(this.state.apiResponse.company && this.state.apiResponse.company.name){
      companyName = this.state.apiResponse.company.name;
    }

    return (
      <Welcome
        shouldTakeBossTest={this.state.shouldTakeBossTest}
        companyName={companyName}
        onStartTest={() => this.goToPage('Reading')}
      />
    );
  }

  renderGoodbye(){

    const emptyOrDefault = (fallbackValue, value) => {
      return (!value || value === "") ? fallbackValue : value;
    }

    const createError = message => {
      return {
        error: true,
        sucess: false,
        message: message
      };
    }

    /*
    const onSignup = async data => {
      // https://stackoverflow.com/a/46181
      const IsValidEmail = email => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return email && email !== '' && re.test(String(email).toLowerCase());
      }

      let email = emptyOrDefault('', data.email);
      let privacy = emptyOrDefault('', data.privacy);
      let name = emptyOrDefault('', data.name);
      let phone = emptyOrDefault('', data.phone);
      let companyName = emptyOrDefault('Personen ' + name, data.companyName);

      if(privacy !== true){
        return createError("Accepter venligst betingelserne.");
      }

      if(!IsValidEmail(email)){
        return createError("Tjek venligst at emailen er korrekt.");
      }

      if(name === ''){
        return createError("Indtast venligst dit navn.");
      }

      let inviteKey = this.state.inviteKey;

      return await this.api.post({
        email: email,
        name: name,
        phone: phone,
        companyName: companyName
      }, 'testcase', inviteKey, 'user_info');
    };*/

    const onSubmitStatistics = async data => {
      let birthYear = emptyOrDefault('', data.birthYear);
      let gender = emptyOrDefault('', data.gender);

      if(birthYear === '' && gender === ''){
        return createError('Indtast venligt fødselsår og/eller køn');
      }

      let inviteKey = this.state.inviteKey;

      return await this.api.post({
        birthYear: birthYear,
        gender: gender
      }, 'testcase', inviteKey, 'update_statistics');

    }

    return (
      <ResultsPage
        shouldTakeBossTest={this.state.shouldTakeBossTest}
        wpm={this.state.apiResponse.wpm}
        noRight={this.state.apiResponse.no_right}
        noWrong={this.state.apiResponse.no_wrong}
        percentCorrect={this.state.apiResponse.correct_percent}
        readingCategory={this.state.apiResponse.reading_category}
        // onSignup={onSignup}
        onSubmitStatistics={onSubmitStatistics}
        trackingHelper={this.trackingHelper}
      />
    );
  }

  renderReading(){
    const onFinishedReading = readingTime => {
      this.setState({ readingTime: readingTime });
      this.goToPage('Questions');
    }
    return (
      <ReadingPage
        text={this.state.apiResponse.text}
        onFinishedReading={onFinishedReading}
      />
    );
  }

  // retryNumber is for trying again multiple times
  async onSubmitAnswers(selectedAnswers, retryNumber = 0){
    this.setLoading();

    let seconds = this.state.readingTime / 1000;

    let response = await this.api.post({
      secondsToRead: seconds,
      answers: selectedAnswers
    }, 'testcase', this.state.inviteKey);

    console.log("response:", response);

    // Set api response
    if(response.error){ // Not successful
      let maxRetry = 0;

      if(retryNumber < maxRetry){ //Should try again
        await sleep(2*1000);
        await this.onSubmitAnswers(selectedAnswers, retryNumber + 1);
      }
      else { // Should not try again
        let err = response;
        err.message = "Kunne ikke gemme dine svar. Fik fejlen: " + response.message;
        this.setState({
          apiResponse: null,
          isLoading: false,
          error: err
        });
      }
    }
    else {
      this.setState({
        apiResponse: response,
        isLoading: false,
        error: null,
        page: 'Goodbye'
      });
      this.goToPage('Goodbye');
    }
  }

  renderQuestions(){

    return (
      <QuestionsPage
        onSubmitAnswers={selectedAnswers => this.onSubmitAnswers(selectedAnswers)}
        questions={this.state.apiResponse.questions}
      />
    );
  }

  renderCookiePopup(){
    let hasMadeTrackingDecision = this.trackingHelper.hasMadeTrackingDecision();
    let hasAcceptedCookies = this.trackingHelper.hasAcceptedTracking();

    const acceptTracking = () => {
      this.trackingHelper.acceptTracking();
      this.forceUpdate();
    };

    const denyTracking = () => {
      this.trackingHelper.denyTracking();
      this.forceUpdate();
    };

    return (
      <CookiePopup
        onAcceptCookies={acceptTracking}
        onDenyCookies={denyTracking}
        hasAcceptedCookies={hasAcceptedCookies}
        renderSmall={hasMadeTrackingDecision}
      />
    );
  }


  // This is a special React function
  // See https://reactjs.org/docs/react-component.html#render
  render() {
    // Inspired by the tutorial:
    // https://css-tricks.com/using-data-in-react-with-the-fetch-api-and-axios/

    return (
      <div className='TestRoot'>
        <LogoHeader />
        {this.renderPage()}
        {this.renderCookiePopup()}
      </div>
    );
  }
}


export default TestRoot;