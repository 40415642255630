import React from 'react';

// import YouTube from './YouTube';
import ResultsDisplay from './ResultsDisplay';
import SocialShareButtons from './SocialShareButtons';
// import StatisticsForm from './StatisticsForm';
// import SignupForm from './SignupForm';
import SignupFormNew from './SignupFormNew';

import { Alert, /*Button */} from 'reactstrap';

import './css/ResultsPage.css';

import { callToActionLink, potentialIncreaseLink, explainLinkRoot, /*callToActionButtonText, callToActionDescription, explainVideo*/ } from '../texts/resultsPage.json';
// import { privacyLink } from '../texts/privacy.json';

// const titleMargin = {marginTop: '25px'};

const calculatePotentialIncrease = (wpm) => {
  let readingPotential = 1000; // the maximal number of WPM achievable generally
  let potentialIncrease = readingPotential / wpm; // how many percent the user could get better
  let potentialIncreaseTxt = Math.round((potentialIncrease * 100) / 10) / 10; // discard all but one digit
  return potentialIncreaseTxt;
}


// TODO: Remove all commented out code
class ResultsPage extends React.Component {

  constructor(props){
    super(props);

    let wpm = Math.floor(this.props.wpm);
    let percentCorrect = this.props.percentCorrect;
    let potentialIncrease = calculatePotentialIncrease(wpm);
    this.explainLink = explainLinkRoot + this.props.readingCategory
      + '?wpm=' + wpm
      + '&correct_pct=' + percentCorrect
      + '&potential_increase=' + potentialIncrease;

  }

  _onClickHelper(label, type, link, shouldOpenLink){
    const trackingHelper = this.props.trackingHelper;
    if(trackingHelper){
      trackingHelper.trackEvent({
        type: type,
        label: label
      });
      if(shouldOpenLink){
        window.open(link, '_blank');
      }
    }
  }

  onClickCallToAction(shouldOpenLink){
    this._onClickHelper('book a meeting', 'buttonClick', callToActionLink, shouldOpenLink);
  };

  // onClickExplainButton(shouldOpenLink){
  //   this._onClickHelper('explain results', 'buttonClick', this.explainLink, shouldOpenLink);
  // }

  // onClickExplainLink(shouldOpenLink){
  //   this._onClickHelper('explain results from potential', 'linkClick', this.explainLink, shouldOpenLink);
  // }

  onClickPotentialIncreaseLink(shouldOpenLink){
    this._onClickHelper('explain results from potential', 'linkClick', potentialIncreaseLink, shouldOpenLink);
  }

  trackShare(platform){
    this._onClickHelper('share to ' + platform, 'buttonClick', null, false);
  }

  // renderSocialShare(){
  //   return (
  //     <div>
  //         <h2 style={titleMargin}>Fortæl dine venner og netværk om testen</h2>
  //         <div>Du deler ikke dit resultat, med mindre du selv fortæller om det</div>
  //         <SocialShareButtons
  //           url="https://test.powerreader.dk/"
  //         />
  //     </div>
  //   );
  // }

  renderSocialShare(){
    return (
      <center style={{marginTop: '30px', marginBottom: '50px'}}>

        <div style={{marginTop: "20px"}}>
          <b>Del testen</b>
          <SocialShareButtons
            style={{marginTop: '7px', marginBottom: '7px'}}
            url="https://test.powerreader.dk/"
            trackShare={platform => this.trackShare(platform)}
            />
          <span style={{color: "gray"}}>Du deler ikke dit resultat, med mindre du selv fortæller om det</span>
        </div>
      </center>
    );
  }

  renderSignupForm(){
    let wpm = Math.floor(this.props.wpm);
    let percentCorrect = this.props.percentCorrect;
    let readingCategory = this.props.readingCategory;
    let potentialIncrease = calculatePotentialIncrease(this.props.wpm);
    let onSubmit = () => window.location.replace(this.explainLink) // Redirect to explaination link
    return (
      <SignupFormNew
        user="timeglasset"
        id="1"
        wpm={wpm}
        percentCorrect={percentCorrect}
        readingCategory={readingCategory}
        potentialIncrease={potentialIncrease}
        onSubmit={onSubmit}
      />
    );
  }



  renderCallToAction(){
    return (
      <React.Fragment>
        { this.renderSignupForm() }
        { this.renderSocialShare() }
      </React.Fragment>
    );

    // return (
    //   <center style={{marginTop: '50px', marginBottom: '50px'}}>

    //     { this.renderSignupForm() }

    //     <div style={{maxWidth: '500px', fontWeight: 'bold'}}>
    //       {callToActionDescription}
    //     </div><br />
    //     <Button
    //         className="next-button"
    //         size="lg"
    //         color="primary"
    //         onClick={ () => this.onClickCallToAction(true) }
    //       >
    //       {callToActionButtonText}
    //     </Button>{' '} <br />
    //     <Button
    //       style={{margin: '15px'}}
    //       onClick={ () => this.onClickExplainButton(true) }
    //     >Uddybning af dit resultat</Button>
    //     <br />
    //     <div style={{marginTop: "20px"}}>
    //       <b>Del testen</b>
    //       <SocialShareButtons
    //         style={{marginTop: '7px', marginBottom: '7px'}}
    //         url="https://test.powerreader.dk/"
    //         trackShare={platform => this.trackShare(platform)}
    //         />
    //       <span style={{color: "gray"}}>Du deler ikke dit resultat, med mindre du selv fortæller om det</span>
    //     </div>
    //   </center>
    // );
  }

  // renderVideo(){
  //   return (
  //     <div>
  //       <h2 style={titleMargin}>Hvad er dit læsepotentiale?</h2>
  //       <YouTube
  //         videoId={explainVideo}
  //       />
  //     </div>
  //   );
  // }

  renderEmployeeMessage(){
    return (
      <Alert color="success">
        PowerReader har modtaget dine svar. Du kan lukke denne side.
      </Alert>
    );
  }

  // renderStatisticsForm(){
  //   let employeeText = (
  //     <React.Fragment>
  //       Det er helt frivilligt at svare.
  //       Dine svar vil blive brugt til statistike formål.
  //       Du accepterer samtidig <a href={privacyLink} target="_blank" rel="noopener noreferrer">vores privatlivspolitik</a>.
  //     </React.Fragment>
  //   );
  //   let bossText = "Dine svar bliver brugt til statistike formål. Dine svar er anonyme.";
  //   return (
  //     <div style={{marginTop: '20px'}}>
  //       <h2 style={titleMargin}>Fortæl om dig selv</h2>
  //       <p>
  //         { this.props.shouldTakeBossTest ? bossText : employeeText }
  //       </p>
  //       <StatisticsForm
  //         onSubmitStatistics = {this.props.onSubmitStatistics}
  //       />
  //     </div>
  //   );
  // }

  renderResults(){
    let wpm = Math.floor(this.props.wpm);
    // let noRight = this.props.noRight;
    // let noWrong = this.props.noWrong;
    // let noTotal = noRight + noWrong;
    let percentCorrect = this.props.percentCorrect;
    let readingCategory = this.props.readingCategory;
    let potentialIncrease = calculatePotentialIncrease(this.props.wpm)

    return (
      <div>
        <h1>Dit resultat</h1>
        <ResultsDisplay
          readingCategory={readingCategory}
          wpm={wpm}
          percentCorrect={percentCorrect}
          potentialIncrease={potentialIncrease}
          potentialIncreaseLink={potentialIncreaseLink}
          onClickPotentialIncreaseLink={() => this.onClickPotentialIncreaseLink(false) }
        />
      </div>
    );
  }

  render() {
    let shouldTakeBossTest = this.props.shouldTakeBossTest;
    return (
      <div className="ResultsPage">
        { this.renderResults() }

        {/* { this.renderSocialShare() } */}

        { shouldTakeBossTest ? this.renderCallToAction() : this.renderEmployeeMessage() }

        {/* { this.renderVideo() } */}

        {/* { this.renderStatisticsForm() } */}

      </div>
    );
  }
}


export default ResultsPage;