import React from 'react';

// For now, we have chosen to just use Active Campaigns own newsletter form
class SignupFormNew extends React.Component {

  componentDidMount() {
      const script = document.createElement("script");

      script.addEventListener('load', (event) => {
        this.onScriptLoaded();
      });


      script.src = "https://"+this.props.user+".activehosted.com/f/embed.php?id=" + this.props.id;
      script.async = true;

      document.body.appendChild(script);
  }

  initializeOnSubmit(){
    // Make the on submit action (eg redirect) work
    // FIXME: Currently only works when there is one form on the page

    document.querySelectorAll('.SignupFormNew form').forEach(form => {
      form.addEventListener('submit', e => {
        setTimeout(() => {
          this.props.onSubmit();
        }, 200); // Wait a bit to make sure the form is submitted

      })
    });
  }

  onScriptLoaded(){
    let wpm = this.props.wpm;
    let percentCorrect = this.props.percentCorrect;
    let readingCategory = this.props.readingCategory;
    let potentialIncrease = this.props.potentialIncrease;
    setTimeout(() => {
      const changeFormValue = (field, value) => {
        document.querySelectorAll("input[data-name='"+field+"']").forEach(elm => elm.value = value);
      }
      changeFormValue("test_wpm", wpm);
      changeFormValue("test_korrekt_pct", percentCorrect);
      changeFormValue("test_kategori", readingCategory);
      changeFormValue("potentiale_for_forbedring", potentialIncrease);

      this.initializeOnSubmit();

    }, 200); // Wait a bit to make sure the form is loaded
  }

  render(){
    return (
      <div className="SignupFormNew">
        <div className="_form_1"></div>
      </div>
    );
  }
}

export default SignupFormNew;