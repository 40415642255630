import React from 'react';
import { Button } from "reactstrap";
import './css/ReadingPage.css';

const getCurrentTime = () => {
  let d = new Date();
  return d.getTime();
}

class ReadingPage extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      startTime: getCurrentTime() // in ms
    }
  }

  renderText(){
    // Adds spacing between newlines
    const newlineParser = s => {
      return s.split("\n").map((t, i) => {
        return (<p key={i}>{t}</p>);
      });
    }
    let text = newlineParser(this.props.text);
    return (
      <div className="text">
        {text}
      </div>
    );
  }

  renderNextButton(){

    const onFinishedReading = () => {
      let readingTime = getCurrentTime() - this.state.startTime;
      this.props.onFinishedReading(readingTime);
    }

    return (
      <div style={{marginTop: '20px'}}>
        <center>
          <b>Jeg er færdig med at læse nu:</b><br />
          <Button
            className="next-button"
            size="lg"
            color="primary"
            onClick={onFinishedReading}
          >
            Stop tiden og gå til spørgsmål
          </Button>
        </center>
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to top
  }

  render() {
    // TO-DO Add "title" attribute to API
    return (
      <div className="ReadingPage">
        <h1>En tur til Berlin</h1>
        {this.renderText()}
        {this.renderNextButton()}
      </div>
    );
  }
}



export default ReadingPage;