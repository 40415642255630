import React from 'react';

import './css/ReadingCategoryTable.css';

class ReadingCategoryTable extends React.Component {

  renderCategory(category, isChosenCategory){
    let number = category.number;
    let title = category.title;
    let color = category.color;

    let rowClassName = "category-row";
    if(isChosenCategory){
      rowClassName = rowClassName + " is-chosen"
    }
    else {
      rowClassName = rowClassName + " is-not-chosen"
    }

    return (
      <div className={rowClassName} style={{ backgroundColor: color }} key={category.number}>
        <span className="number">{number}. </span>
        <span className="title">{title}</span>
      </div>
    );
  }

  render(){
    let categoryNumber = this.props.readingCategory;
    let categories = this.props.categories;

    let renderedCategories = categories.map(category => {
      return this.renderCategory(category, categoryNumber === category.number)
    });

    return (
      <div className="ReadingCategoryTable">
        <div className="category-table">
          {renderedCategories}
        </div>
      </div>
    );
  }
}



export default ReadingCategoryTable;