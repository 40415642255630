import React from 'react';

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

// https://stackoverflow.com/a/36953272
const range = (start, end) => {
  return [...Array(1+end-start).keys()].map(v => start+v)
}

class AadminPagination extends React.Component {

  render() {
    const switchPage = number => this.props.onSwitchPage(number);

    const renderItem = (currentPage, number) => {
      let isActive = currentPage === number;
      return (
        <PaginationItem key={number} active={isActive}>
          <PaginationLink href="#" onClick={() => switchPage(number)}>
            {number + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }

    const currentPage = this.props.currentPage;
    const lastPage = this.props.lastPage;

    if(lastPage === 0){
      return '';
    }

    let items = range(0, lastPage).map(n => renderItem(currentPage, n));


    return (
      <Pagination aria-label="Pagnation">
        {items}
      </Pagination>
    );
  }
}


export default AadminPagination;