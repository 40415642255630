import React from 'react';
import { Table } from 'reactstrap';


class ResultsTable extends React.Component {

  renderResult(person, key){

    let invite = null
    let wpm = null
    let percentCorrect = null

    let testCases = person.test_cases

    if(testCases.length > 0){
      let testCase = testCases[0] // Fixme: Currently, only one test case is shown per person.
      invite = testCase.invite_key

      let result = testCase.results.length > 0 ? testCase.results[0] : null;
      if(result){
        wpm = result.wpm
        let noRight = parseInt(result.no_right);
        let noWrong = parseInt(result.no_wrong);
        percentCorrect = Math.floor( (noRight / (noRight + noWrong)) * 100 );
      }

    }

    let inviteText = invite && !wpm ?  // Has unanswered test
      (<a
        href={'/invite/' + invite}
        target="_blank"
        rel="noopener noreferrer"
        >Se</a>)
      : '-';

    return (
      <tr key={key}>
        <td>{person.email}</td>
        <td>{inviteText}</td>
        <td>{wpm ? wpm : '-'}</td>
        <td>{(typeof percentCorrect === "number") ? percentCorrect + '%' : '-'}</td>
      </tr>
    );
  }

  render() {
    let people = this.props.people;
    return (
      <div className="ResultsTable">
        <Table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Invitation</th>
              <th>WPM</th>
              <th>Korrekte svar</th>
            </tr>
          </thead>
          <tbody>
          {people.map(this.renderResult)}
          </tbody>
        </Table>
      </div>
    );
  }
}


export default ResultsTable;