import React from 'react';

import { Table, Button } from 'reactstrap';

// Components:
import CreateCompanyForm from './CreateCompanyForm';
import AdminPagination from './AdminPagination';



const formatISODate = date => {
  return date.substring(0,10);
}

class ShowCompanies extends React.Component {

  renderCompany(company, key){
    //
    return (
      <tr key={key}>
        <th scope="row">{company.id}</th>
        <td>{company.name}</td>
        <td>{formatISODate(company.created_at)}</td>
        <td>
          <Button
            onClick={() => this.props.onSelectCompany(company.id)}
          >
            Se mere
          </Button>
        </td>
      </tr>
    );
  }

  renderPagination(){
    return (
      <AdminPagination
        currentPage={this.props.currentPage}
        lastPage={this.props.lastPage}
        onSwitchPage={number => this.props.onSwitchPage(number)}
      />
    );
  }

  render() {
    let companies = this.props.companies;
    return (
      <div className="ShowCompanies">
        <div>
          <h2>Firmaer</h2>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Navn</th>
                <th>Oprettet</th>
                <th>Handling</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company, index) => this.renderCompany(company, index)) }
            </tbody>
          </Table>
          {this.renderPagination()}
        </div>
        <div>
          <h2>Opret firma</h2>
          <CreateCompanyForm
            onCreate={(name, comments) => this.props.onCreateCompany(name, comments)}
          />
        </div>
      </div>
    );
  }
}


export default ShowCompanies;