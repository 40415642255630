import React from 'react';

import { Button, Form, FormGroup, Label, Input, Spinner} from 'reactstrap';

// https://advancedweb.hu/how-to-use-async-functions-with-array-filter-in-javascript/
const asyncFilter = async (arr, predicate) => {
	const results = await Promise.all(arr.map(predicate));

	return arr.filter((_v, index) => results[index]);
}

// From Admin panel to sign employees up in bulk
class EmployeeSignupForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      emails: '',
      deadline: '',
      loading: false,
      message: ''
    }
  }

  getTestId(){ // TO-DO: Make test selectable
    return 1;
  }

  setLoading(){
    this.setState({ loading: true });
  }

  setNotLoading(){
    this.setState({ loading: false });
  }

  setMessage(msg){
    this.setState({ message: msg });
  }

  renderLoading(){
    return (
      <div>
        Vent venligst <br />
        <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
      </div>
    );
  }

  async onSignup(e){
    e.preventDefault();
    this.setLoading();

    let deadline = this.state.deadline;
    if(deadline.trim() === ""){
      this.setMessage('Vælg venligst en deadline');
      this.setNotLoading();
      return;
    }

    let testId = this.getTestId();
    let emailString = this.state.emails;
    let emails = emailString.split(/[\n,]+/).map(s => s.trim());

    if(emailString.trim() === ""){
      this.setMessage('Kunne ikke finde nogen mailaddresser');
      this.setNotLoading();
      return;
    }

    const notSignedUp = await asyncFilter(emails, async email => {
      return !await this.props.onSignup(testId, email, deadline); // Signup, only keep mail on fail
    });


    console.log(notSignedUp);

    if(notSignedUp.length > 0){
      this.setMessage('De tilbageværende mailadresser kunne ikke tilmeldes.');
      this.setState({
        emails: notSignedUp.join('\n')
      });
    }
    else {
      this.setMessage('Alle mailaddresser blev tilmeldt');
      this.setState({
        emails: ''
      });
    }

    this.setNotLoading();
  }


  setEmails(e){
    this.setState({
      emails: e.target.value
    });
  }

  setDeadline(e){
    this.setState({
      deadline: e.target.value
    });
  }

  render() {
    if(this.state.loading){
      return this.renderLoading();
    }
    return (
      <Form>
        <b>{this.state.message}</b>
        <FormGroup>
          <Label for="deadline">Deadline for tests</Label>
          <Input
            type="date"
            name="deadline"
            onChange={e => this.setDeadline(e)}
            placeholder="date placeholder"
            value={this.state.deadline}
          />
        </FormGroup>
        <FormGroup>
          <Label for="signupEmails">Tilføj disse mailaddresser til en test:</Label>
          <Input
            type="textarea"
            name="signupEmails"
            onChange={e => this.setEmails(e)}
            value={this.state.emails}
          />
        </FormGroup>
        <Button onClick={e => this.onSignup(e)}>Tilmeld</Button>
      </Form>
    );

  }
}


export default EmployeeSignupForm;