import React from 'react';

import './css/AdminMenu.css'

import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';


class AdminMenu extends React.Component {

  render(){
    return (
      <div className="AdminMenu">
        <Navbar color="dark" dark expand="md">
          <NavbarBrand>PowerReader</NavbarBrand>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink href="#" onClick={e => this.props.selectShowCompanies()}>Alle firmaer</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" onClick={e => this.props.selectShowStatistics()}>Statistik</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" onClick={e => this.props.onLogout()}>Log ud</NavLink>
              </NavItem>
            </Nav>
        </Navbar>
      </div>
    );
  }

}


export default AdminMenu;