import React from 'react';
import { Table } from 'reactstrap';

const floatToPct = (pct_float) => {
  return Math.floor(pct_float * 100);
}

const pctPretty = (pct_float) => {
  return floatToPct(pct_float) + "%";
}

const generatePctCorrectValObj = (pct_correct_float) => {
  return {
    progress: pct_correct_float,
    progressColor: "rgba(68, 255, 0, 0.44)",
    text: pctPretty(pct_correct_float)
  };
};

class ShowStatistics extends React.Component {

  generateTable(data){

    const generateRow = (row, key, isHeader = false) => {
      // Generate either <th> or <td>, with a progress bar if nessecary
      const generateForVal = (isHeader, val, key) => {
        const generateTd = (text, style, key) => (<td style={style} key={key}>{text}</td>);
        const generateTh = (text, style, key) => (<th style={style} key={key}>{text}</th>);

        let text = val;
        let style = {};
        // should display a progress bar (for instance, the percent of the total)
        if(val && val.progress){
          text = val.text;
          style.background =
            "linear-gradient(to right, "
            + val.progressColor
            + " "
            + pctPretty(val.progress)
            + ",#ffffff "
            + pctPretty(val.progress)
            + ")";
        }

        return isHeader ? generateTh(text, style, key) : generateTd(text, style, key)
      };


      return (
        <tr key={key}>
          {row.map((val, i) => generateForVal(isHeader, val, i))}
        </tr>
      );
    }

    let headRow = data.shift(); // return and remove first element

    return (
      <Table>
        <thead>
          {generateRow(headRow, 0, true)}
        </thead>
        <tbody>
          {data.map((row, i) => generateRow(row, i))}
        </tbody>
      </Table>
    );

  }

  renderBasticStats(){
    const all = this.props.all;
    let data = [
      ["Egenskab", "Værdi"],
      ["Total antal tests", all.count],
      ["Total page hits og inviterede", all.count_page_hits],
      ["Andel der færdiggør testen", pctPretty(all.count / all.count_page_hits)],
      ["Gennemsnitlig ord pr. minut", all.avg_wpm],
      ["Gennemsnitlig % korrekt", pctPretty(all.avg_pct_correct)]
    ];
    return this.generateTable(data);
  }

  renderByBirthYear(){
    let header =  [
      "År",
      "Antal",
      "Gns. ord pr. minut",
      "Gns. % korrekt"
    ];
    let rows = this.props.byBirthYear.map(obj => {
      let years = "Ikke oplyst";
      if(obj.birth_year_group){
        let start_year = parseInt(obj.birth_year_group);
        years = start_year + " - " + (start_year + 9);
      }

      return [
        years,
        obj.num_in_group,
        obj.avg_wpm,
        generatePctCorrectValObj(obj.avg_pct_correct)
      ];
    });
    return this.generateTable([header, ...rows]);
  }

  renderByReadingCategory(){
    let header =  [
      "Kategori",
      "Antal",
      "Gns. ord pr. minut",
      "Gns. % korrekt"
    ];

    let allGroupsCount = this.props.byWpm.reduce((acc, curr) => acc + parseInt(curr.num_in_group), 0);

    let rows = this.props.byReadingCategory.map(obj => {
      let num_in_group = parseInt(obj.num_in_group);
      let pct_of_total = num_in_group / allGroupsCount;

      let num_and_pct = {
        progress: pct_of_total,
        progressColor: "#efe3af",
        text: num_in_group + " (" + pctPretty(pct_of_total)  + ")"
      };

      return [
        obj.reading_category,
        num_and_pct,
        Math.floor(obj.avg_wpm),
        generatePctCorrectValObj(obj.avg_pct_correct)
      ];
    });
    return this.generateTable([header, ...rows]);
  }

  renderByGender(){
    let header =  [
      "Køn",
      "Antal",
      "Gns. ord pr. minut",
      "Gns. % korrekt"
    ];
    let rows = this.props.byGender.map(obj => {
      let gender = obj.gender ? obj.gender : "Ikke oplyst";
      return [
        gender,
        obj.num_in_group,
        obj.avg_wpm,
        generatePctCorrectValObj(obj.avg_pct_correct)
      ];
    });
    return this.generateTable([header, ...rows]);
  }

  renderByWpm(){
    let header =  [
      "Ord pr. minut",
      "Antal (andel)",
      "Gns. % korrekt"
    ];

    let allGroupsCount = this.props.byWpm.reduce((acc, curr) => acc + parseInt(curr.num_in_group), 0);

    let rows = this.props.byWpm.map(obj => {
      let wpm_start = parseInt(obj.wpm_group);
      let wpm = wpm_start + " - " + (wpm_start + 99);

      let num_in_group = parseInt(obj.num_in_group);
      let pct_of_total = num_in_group / allGroupsCount;

      let num_and_pct = {
        progress: pct_of_total,
        progressColor: "#efe3af",
        text: num_in_group + " (" + pctPretty(pct_of_total)  + ")"
      };

      return [
        wpm,
        num_and_pct,
        generatePctCorrectValObj(obj.avg_pct_correct)
      ];
    });
    return this.generateTable([header, ...rows]);
  }

  render(){

    return (
      <div className="ShowStatistics">
        <h1>Statistk</h1>
        <p>
          Alle usandsynlige resultater er sorteret fra (f.eks. med meget høj læsehastighed).
        </p>

        <h3>Nøgletal</h3>
        { this.renderBasticStats() }

        {/* <h3>Efter fødselsår</h3>
        { this.renderByBirthYear() }

        <h3>Efter køn</h3>
        { this.renderByGender() } */}

        <h3>Fordeling efter ord pr. minut</h3>
        { this.renderByWpm() }

        <h3>Fordeling efter kategori</h3>
        { this.renderByReadingCategory() }
      </div>
    );
  }
}



export default ShowStatistics;